import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { Product } from 'common/components/templates/Product'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ProductPageProps {
    pageContext: any
}

export const ProductPage: React.FC<ProductPageProps> = ({ pageContext }) => {
    return <Product {...getProductData(pageContext)} />
}

export default ProductPage

export const getProductData = (data: any) => ({
    layout: getLayoutData(data),
    featureList: {
        image: data.features[0].image,
        title: data.features[0].title,
        features: data.features[0].benefits
    },
    jumbotron: getJumbotronData(data),
    resources: {
        title: data.resources_title,
        text: data.resources_text,
        downloads: data.downloads
    },
    campaignFeature: {
        title: data.title,
        callToAction: {
            [`${data.call_to_action__iconlocation}Icon`]: data.call_to_action__icon,
            url: data.call_to_action__url,
            text: data.call_to_action__text,
            title: data.call_to_action__title,
            variant: data.call_to_action__variant,
            analyticsId: data.call_to_action__analytics_id,
            label: data.call_to_action__label
        },
        text: data.text,
        image: data.image
    },
    video: {
        height: data.video__height,
        width: data.video__width,
        url: data.video__url,
        source: data.video__video,
        title: data.video__title,
        text: data.video__text
    },
    benefits: {
        title: data.threecards__title,
        subtitle: data.threecards__subtitle,
        benefits: (data.threecards__cards || []).map((card: any) => ({
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            },
            icon: card.icon,
            text: card.text,
            title: card.title
        }))
    }
})
