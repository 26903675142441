import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import {
    FeatureList,
    FeatureListProps
} from 'common/components/organisms/FeatureList'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import {
    Resources,
    ResourcesProps
} from 'common/components/organisms/Resources'
import { CampaignFeatureProps } from 'common/components/organisms/CampaignFeature'
import { Video } from 'common/components/atoms/Video'
import React from 'react'
import { CampaignFeature } from '@irishlife/ilgroupdesignsystem.organisms.campaign-feature'
import { getCampaignFeatureProps } from 'common/utils/geCampaignFeatureProps'

export interface ProductProps {
    featureList: FeatureListProps
    jumbotron: JumbotronProps
    layout: LayoutProps
    resources: ResourcesProps
    benefits: BenefitsProps
    campaignFeature: CampaignFeatureProps
    video: any
}

export const Product: React.FC<ProductProps> = ({
    featureList,
    jumbotron,
    layout,
    resources,
    benefits,
    campaignFeature,
    video
}) => {

    const campaignFeatureProps = getCampaignFeatureProps(campaignFeature)

    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <Wrapper>
                <Spacer />
                <section>
                    <FeatureList {...featureList} />
                </section>
                <Spacer />
                {video &&
                    <>
                        <Video {...video} />
                    </>
                }
                {campaignFeature.title &&
                    <section>
                        <CampaignFeature {...campaignFeatureProps} />
                    </section>
                }
                {resources.title && (
                    <>
                        <Spacer />
                        <Resources {...resources} />
                    </>
                )}
                {benefits.title && (
                    <section>
                        <Spacer />
                        <Benefits {...benefits} />
                    </section>
                )}
            </Wrapper>
        </Layout>
    )
}
